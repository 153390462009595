<template>
  <v-container>
    <v-layout
      wrap
      align-center
    >
      <v-flex
        xs12
        sm12
        md12
        lg12
        xl12
        grow
        pa-1
      >
        <v-card>
          <v-toolbar
            dark
            color="primary"
          >
            <v-toolbar-title>ZTE ONU Configuration Command Generator</v-toolbar-title>
          </v-toolbar>
          <v-form
            ref="form"
            v-model="valid"
          >
            <v-layout
              wrap
              align-center
            >
              <v-flex
                xs2
                sm2
                md2
                lg2
                xl2
                grow
                pa-1
              >
                <v-select
                  v-model="oltVersion"
                  :items="oltVersionItems"
                  item-text="label"
                  item-value="value"
                  label="OLT Version"
                  :rules="oltVersionRules"
                  required
                />
              </v-flex>

              <v-flex
                xs2
                sm2
                md2
                lg2
                xl2
                grow
                pa-1
              >
                <v-select
                  v-model="Shelf"
                  :items="shelfItems"
                  item-text="label"
                  item-value="value"
                  label="Shelf"
                  :rules="shelfRules"
                  required
                />
              </v-flex>

              <v-flex
                xs2
                sm2
                md2
                lg2
                xl2
                grow
                pa-1
              >
                <v-select
                  v-model="Slot"
                  :items="slotItems"
                  item-text="label"
                  item-value="value"
                  label="Slot"
                  :rules="slotRules"
                  required
                  @change="calServiceVlan"
                />
              </v-flex>

              <v-flex
                xs2
                sm2
                md2
                lg2
                xl2
                grow
                pa-1
              >
                <v-select
                  v-model="PON"
                  :items="ponItems"
                  item-text="label"
                  item-value="value"
                  label="PON"
                  :rules="ponRules"
                  required
                />
              </v-flex>

              <v-flex
                xs2
                sm2
                md2
                lg2
                xl2
                grow
                pa-1
              >
                <v-text-field
                  v-model="ONU"
                  label="ONU"
                  mask="###"
                  :rules="onuRules"
                  required
                />
              </v-flex>
            </v-layout>

            <v-layout
              align-start
              justify-start
            >
              <v-flex
                xs2
                sm2
                md2
                lg2
                xl2
                grow
                pa-1
              >
                <v-select
                  v-model="onuType"
                  :items="onuTypeItems"
                  item-text="label"
                  item-value="value"
                  label="ONU Type"
                  :rules="onuTypeRules"
                  required
                  @change="onuTypeChanged"
                />
              </v-flex>

              <v-flex
                xs2
                sm2
                md2
                lg2
                xl2
                grow
                pa-1
              >
                <v-select
                  v-model="onuMode"
                  :items="onuModeItems"
                  item-text="label"
                  item-value="value"
                  label="ONU Mode"
                  :rules="onuModeRules"
                  required
                  @change="onuModeChanged"
                />
              </v-flex>

              <v-flex
                xs4
                sm4
                md4
                lg4
                xl4
                grow
                pa-1
              >
                <v-text-field
                  v-model="onuSN"
                  v-mask="onuSNMask"
                  label="S/N"
                  counter="12"
                  :rules="onuSNRules"
                  required
                />
              </v-flex>

              <v-flex
                v-if="onuVoIPPort > 0"
                xs1
                sm1
                md1
                lg1
                xl1
                grow
                pa-1
              >
                <v-checkbox
                  v-model="voip1Enable"
                  label="VoIP 1"
                  :disabled="voip1EnableCheckboxDisabled == 1"
                  @change="voip1EnableChanged"
                />
              </v-flex>

              <v-flex
                v-if="onuVoIPPort > 1"
                xs1
                sm1
                md1
                lg1
                xl1
                grow
                pa-1
              >
                <v-checkbox
                  v-model="voip2Enable"
                  label="VoIP 2"
                  @change="voip2EnableChanged"
                />
              </v-flex>

              <v-flex
                v-if="onuEthernetPort > 1"
                xs1
                sm1
                md1
                lg1
                xl1
                grow
                pa-1
              >
                <v-checkbox
                  v-model="externalATAEnable"
                  label="External ATA"
                  @change="externalATAEnableChanged"
                />
              </v-flex>
            </v-layout>


            <v-layout
              wrap
              align-center
            >
              <v-flex
                xs4
                sm4
                md4
                lg4
                xl4
                grow
                pa-1
              >
                <v-text-field
                  v-model="username"
                  v-mask="usernameMask"
                  label="Username"
                  counter="9"
                  :rules="usernameRules"
                  required
                />
              </v-flex>

              <v-flex
                v-if="onuMode === 'route' | onuMode === 'bridge'"
                xs2
                sm2
                md2
                lg2
                xl2
                grow
                pa-1
              >
                <v-select
                  v-model="domain"
                  :items="domainItems"
                  item-text="label"
                  item-value="value"
                  label="Domain"
                  :rules="domainRules"
                  required
                  @change="calServiceVlan"
                />
              </v-flex>

              <v-flex
                v-if="onuMode === 'route'"
                xs2
                sm2
                md2
                lg2
                xl2
                grow
                pa-1
              >
                <v-text-field
                  v-model="internetPassword"
                  label="Password"
                  :rules="internetPasswordRules"
                  required
                />
              </v-flex>
              <v-flex
                xs4
                sm4
                md4
                lg4
                xl4
                grow
                pa-1
              >
                <v-text-field
                  v-if="onuMode === 'route' | onuMode === 'bridge'"
                  v-model="serviceVLAN"
                  v-mask="vlanMask"
                  label="Service VLAN"
                  :rules="serviceVLANRules"
                  required
                />
              </v-flex>
            </v-layout>

            <v-layout
              v-if="voip1Enable || voip2Enable || externalATAEnable"
              wrap
              align-center
            >
              <v-flex
                xs4
                sm4
                md4
                lg4
                xl4
                grow
                pa-1
              >
                <v-text-field
                  v-model="sipVLAN"
                  v-mask="vlanMask"
                  label="SIP VLAN"
                  :rules="sipVLANRules"
                  required
                />
              </v-flex>
              <v-flex
                xs4
                sm4
                md4
                lg4
                xl4
                grow
                pa-1
              >
                <v-select
                  v-if="voip1Enable || voip2Enable"
                  v-model="sipDomain"
                  :items="sipDomainItems"
                  item-text="label"
                  item-value="value"
                  label="SIP Domain"
                  :rules="sipDomainRules"
                  required
                />
              </v-flex>
            </v-layout>

            <v-layout
              v-if="voip1Enable"
              wrap
              align-center
            >
              <v-flex
                xs4
                sm4
                md4
                lg4
                xl4
                grow
                pa-1
              >
                <v-text-field
                  v-model="voip1Username"
                  v-mask="voipUsernameMask"
                  label="VoIP 1 Username"
                  counter="9"
                  :rules="voip1UsernameRules"
                  required
                />
              </v-flex>
              <v-flex
                xs2
                sm2
                md2
                lg2
                xl2
                grow
                pa-1
              >
                <v-text-field
                  v-model="voip1Password"
                  label="Password"
                  :rules="voip1PasswordRules"
                  required
                />
              </v-flex>
            </v-layout>

            <v-layout
              v-if="voip2Enable"
              wrap
              align-center
            >
              <v-flex
                xs4
                sm4
                md4
                lg4
                xl4
                grow
                pa-1
              >
                <v-text-field
                  v-model="voip2Username"
                  v-mask="voipUsernameMask"
                  label="VoIP 2 Username"
                  counter="9"
                  :rules="voip2UsernameRules"
                  required
                />
              </v-flex>
              <v-flex
                xs2
                sm2
                md2
                lg2
                xl2
                grow
                pa-1
              >
                <v-text-field
                  v-model="voip2Password"
                  label="Password"
                  :rules="voip2PasswordRules"
                  required
                />
              </v-flex>
            </v-layout>

            <v-layout
              wrap
            >
              <v-flex
                xs4
                sm4
                md4
                lg4
                xl4
                grow
                pa-1
              >
                <v-text-field
                  v-model="description"
                  label="Description"
                  :rules="descriptionRules"
                />
              </v-flex>
            </v-layout>

            <v-layout
              wrap
            >
              <v-flex
                xs4
                sm4
                md4
                lg4
                xl4
                grow
                pa-1
              >
                <v-checkbox
                  v-model="deleteONU"
                  label="Delete Existing ONU"
                />
              </v-flex>
            </v-layout>

            <v-card-actions>
              <v-btn
                color="primary"
                @click="generateOnuConfig"
              >
                Generate<v-icon
                  right
                  dark
                >
                  mdi-settings-outline
                </v-icon>
              </v-btn>
              <v-btn
                color="error"
                @click="clearConfig"
              >
                Clear<v-icon
                  right
                  dark
                >
                  mdi-undo
                </v-icon>
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-flex>
    </v-layout>

    <v-layout
      wrap
      align-center
    >
      <v-flex
        xs12
        sm12
        md12
        lg12
        xl12
        grow
        pa-1
      >
        <v-card>
          <v-toolbar
            dark
            color="primary"
          >
            <v-toolbar-title>Configuration</v-toolbar-title>
          </v-toolbar>
          <v-card-text class="custom-monospace-textarea">
            <p class="text-sm-left black--text">
              <pre>{{ configOutput }}</pre>
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              v-clipboard:copy="configOutput"
              v-clipboard:success="onCopy"
              v-clipboard:error="onCopyError"
              color="primary"
            >
              Copy<v-icon
                right
                dark
              >
                mdi-content-copy
              </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>


    <v-layout
      v-if="showCommentConfig"
      wrap
      align-center
    >
      <v-flex
        xs12
        sm12
        md12
        lg12
        xl12
        grow
        pa-1
      >
        <v-card>
          <v-toolbar
            dark
            color="primary"
          >
            <v-toolbar-title>{{ configCommentTitle1 }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text class="custom-monospace-textarea">
            <p class="text-sm-left black--text">
              <pre>{{ configCommentOutput1 }}</pre>
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              v-clipboard:copy="configCommentOutput1"
              v-clipboard:success="onCopy"
              v-clipboard:error="onCopyError"
              color="primary"
            >
              Copy<v-icon
                right
                dark
              >
                mdi-content-copy
              </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>

    <v-layout
      v-if="showCommentConfig"
      wrap
      align-center
    >
      <v-flex
        xs12
        sm12
        md12
        lg12
        xl12
        grow
        pa-1
      >
        <v-card>
          <v-toolbar
            dark
            color="primary"
          >
            <v-toolbar-title>{{ configCommentTitle2 }}</v-toolbar-title>
          </v-toolbar>
          <v-card-text class="custom-monospace-textarea">
            <p class="text-sm-left black--text">
              <pre>{{ configCommentOutput2 }}</pre>
            </p>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn
              v-clipboard:copy="configCommentOutput2"
              v-clipboard:success="onCopy"
              v-clipboard:error="onCopyError"
              color="primary"
            >
              Copy<v-icon
                right
                dark
              >
                mdi-content-copy
              </v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>


<script>
import { mask } from 'vue-the-mask'

export default {
  name: 'GenerateZTEConfig',
  directives: {
    mask,
  },
  data() {
    return {
      onuSNMask: {
        mask: 'AAAAFFFFFFFF',
        tokens: {
          A: {
            pattern: /[a-zA-Z]/,
            transform: v => v.toLocaleUpperCase(),
          },
          F: {
            pattern: /[0-9a-fA-F]/,
            transform: v => v.toLocaleUpperCase(),
          },
        },
      },
      usernameMask: '####a####',
      vlanMask: '####',
      voipUsernameMask: '#########',
      onuModeItems: [
      ],
      domainItems: [
        { label: '@fttxhome', value: '@fttxhome' },
        { label: '@fttxbiz', value: '@fttxbiz' },
        { label: '@government', value: '@government' },
        { label: '@obec-net', value: '@obec-net' },
      ],
      sipDomainItems: [
        { label: 'sipp10', value: 'sipp10' },
        { label: 'sipp11', value: 'sipp11' },
        { label: 'sipp32', value: 'sipp32' },
      ],
      oltVersionItems: [
        // { label: 'V1.X.X', value: 1 },
        // { label: 'V2.X.X', value: 2 },
        { label: 'C3XX V1', value: 'C3XX V1' },
        { label: 'C3XX V2', value: 'C3XX V2' },
        { label: 'C6XX V1', value: 'C6XX V1' },
      ],
      shelfItems: [
        { label: '1', value: 1 },
      ],
      slotItems: [
        { label: '1', value: 1 },
        { label: '2', value: 2 },
        { label: '3', value: 3 },
        { label: '4', value: 4 },
        { label: '5', value: 5 },
        { label: '6', value: 6 },
        { label: '7', value: 7 },
        { label: '8', value: 8 },
        { label: '9', value: 9 },
        { label: '10', value: 10 },
        { label: '11', value: 11 },
        { label: '12', value: 12 },
        { label: '13', value: 13 },
        { label: '14', value: 14 },
        { label: '15', value: 15 },
        { label: '16', value: 16 },
        { label: '17', value: 17 },
        { label: '18', value: 18 },
      ],
      ponItems: [
        { label: '1', value: 1 },
        { label: '2', value: 2 },
        { label: '3', value: 3 },
        { label: '4', value: 4 },
        { label: '5', value: 5 },
        { label: '6', value: 6 },
        { label: '7', value: 7 },
        { label: '8', value: 8 },
        { label: '9', value: 9 },
        { label: '10', value: 10 },
        { label: '11', value: 11 },
        { label: '12', value: 12 },
        { label: '13', value: 13 },
        { label: '14', value: 14 },
        { label: '15', value: 15 },
        { label: '16', value: 16 },
      ],
      onuTypeItems: [
        { label: 'F601', value: 'ZTE-F601' },
        { label: 'F612', value: 'ZTE-F612' },
        { label: 'F620', value: 'ZTE-F620' },
        { label: 'F600W', value: 'ZTE-F600W' },
        { label: 'F660', value: 'ZTE-F660' },
        { label: 'F6600', value: 'ZTE-F6600' },
        { label: 'F670', value: 'ZTE-F670' },
        { label: 'F670L', value: 'ZTE-F670L' },
        { label: 'F680', value: 'ZTE-F680' },
        { label: 'F6201B', value: 'ZTE-F6201B' },
        { label: 'F220', value: 'ZTE-F220' },
        { label: 'F260A', value: 'ZTE-F260A' },
      ],
      valid: true,
      onuType: '',
      onuSN: '',
      onuEthernetPort: 0,
      onuVoIPPort: 0,
      voip1Enable: false,
      voip1EnableCheckboxDisabled: false,
      voip2Enable: false,
      externalATAEnable: false,
      username: '',
      onuMode: '',
      domain: '@fttxhome',
      internetPassword: '',
      serviceVLAN: '',
      sipDomain: '',
      sipVLAN: '',
      voip1Username: '',
      voip1Password: '',
      voip2Username: '',
      voip2Password: '',
      description: '',
      oltVersion: 'C3XX V2',
      Shelf: 1,
      Slot: '',
      PON: '',
      ONU: '',
      deleteONU: false,
      configOutput: '',
      showCommentConfig: false,
      configCommentTitle: '',
      configCommentOutput: '',
      onuTypeRules: [
        v => !!v || 'ONU Type is required',
      ],
      onuModeRules: [
        v => !!v || 'ONU Mode is required',
      ],
      onuSNRules: [
        v => !!v || 'S/N is required',
        v => v.length === 12 || 'S/N must be 12 characters',
      ],
      usernameRules: [
        v => !!v || 'Username is required',
        v => v.length === 9 || 'Username must be 9 characters',
      ],
      domainRules: [
        v => !!v || 'Domain is required',
      ],
      internetPasswordRules: [
        v => !!v || 'Password is required',
      ],
      serviceVLANRules: [
        v => !!v || 'Service VLAN is required',
        v => v <= 4096 || 'VLAN must be 1-4096',
      ],
      sipDomainRules: [
        v => !!v || 'SIP Domain is required',
      ],
      sipVLANRules: [
        v => !!v || 'SIP VLAN is required',
        v => v <= 4096 || 'VLAN must be 1-4096',
      ],
      voip1UsernameRules: [
        v => !!v || 'Username is required',
        v => v.length === 9 || 'Username must be 9 characters',
      ],
      voip1PasswordRules: [
        v => !!v || 'Password is required',
      ],
      voip2UsernameRules: [
        v => !!v || 'Username is required',
        v => v.length === 9 || 'Username must be 9 characters',
      ],
      voip2PasswordRules: [
        v => !!v || 'Password is required',
      ],
      oltVersionRules: [
        v => !!v || 'OLT Version is required',
      ],
      shelfRules: [
        v => !!v || 'Shelf is required',
      ],
      slotRules: [
        v => !!v || 'Slot is required',
      ],
      ponRules: [
        v => !!v || 'PON is required',
      ],
      onuRules: [
        v => !!v || 'ONU is required',
        v => v <= 128 || 'ONU must be 1-128',
      ],
      descriptionRules: [
        v => v === '' || /^[a-zA-Z0-9#()+-_=[\]{}@ /\\:.]{1,30}$/.test(v) || 'Description must be English 1-30 characters',
      ],
    }
  },
  /*
  props: {
    configOutputText: String
  },
*/

  methods: {
    generateOnuConfig() {
      this.configOutput = ''
      if (this.$refs.form.validate()) {
        if (this.oltVersion === 'C3XX V1') {
          this.configOutput = '!\r\n'
          this.configOutput += `interface gpon-olt_${this.Shelf}/${this.Slot}/${this.PON}\r\n`
          if (this.deleteONU) {
            this.configOutput += `  no onu ${this.ONU}\r\n`
          }
          this.configOutput += `  onu ${this.ONU} type ${this.onuType} sn ${this.onuSN}\r\n`
          this.configOutput += '!\r\n'
          this.configOutput += `interface gpon-onu_${this.Shelf}/${this.Slot}/${this.PON}:${this.ONU}\r\n`
          this.configOutput += '  name '
          if (this.username !== '') {
            this.configOutput += `${this.username}`
            if (this.voip1Username !== '') {
              this.configOutput += ', '
            }
          }
          if (this.voip1Username !== '') {
            this.configOutput += `${this.voip1Username}`
            if (this.voip2Username !== '') {
              this.configOutput += ', '
            }
          }
          if (this.voip2Username !== '') {
            this.configOutput += `${this.voip2Username}`
          }
          this.configOutput += ' |'
          if (this.description !== '') {
            this.configOutput += ` ${this.description}`
          }
          this.configOutput += '\r\n'
          this.configOutput += '  sn-bind disable\r\n'
          if ((this.onuMode === 'route') || (this.onuMode === 'bridge')) {
            this.configOutput += '  tcont 1 profile UNLIMITED_UP\r\n'
          }
          if (this.voip1Enable || this.voip2Enable || this.externalATAEnable) {
            this.configOutput += '  tcont 2 profile VOIP512K\r\n'
          }
          if ((this.onuMode === 'route') || (this.onuMode === 'bridge')) {
            this.configOutput += '  gemport 1 unicast tcont 1 dir both\r\n'
            this.configOutput += '  gemport 1 traffic-limit downstream UNLIMITED_DOWN\r\n'
          }
          if (this.voip1Enable || this.voip2Enable || this.externalATAEnable) {
            this.configOutput += '  gemport 2 unicast tcont 2 dir both\r\n'
            this.configOutput += '  gemport 2 traffic-limit downstream VOIP512K\r\n'
          }
          if ((this.onuMode === 'route') || (this.onuMode === 'bridge')) {
            this.configOutput += '  max-mac-learn 2 vport 1\r\n'
          }
          if (this.voip1Enable || this.voip2Enable || this.externalATAEnable) {
            this.configOutput += '  max-mac-learn 2 vport 2\r\n'
          }
          if ((this.onuMode === 'route') || (this.onuMode === 'bridge')) {
            this.configOutput += '  switchport mode hybrid vport 1\r\n'
          }
          if (this.voip1Enable || this.voip2Enable || this.externalATAEnable) {
            this.configOutput += '  switchport mode hybrid vport 2\r\n'
          }
          if ((this.onuMode === 'route') || (this.onuMode === 'bridge')) {
            this.configOutput += `  service-port 1 vport 1 user-vlan 10 vlan ${this.serviceVLAN}\r\n`
          }
          if (this.voip1Enable || this.voip2Enable || this.externalATAEnable) {
            this.configOutput += `  service-port 2 vport 2 user-vlan 20 vlan ${this.sipVLAN}\r\n`
          }
          this.configOutput += '!\r\n'
          this.configOutput += `pon-onu-mng gpon-onu_${this.Shelf}/${this.Slot}/${this.PON}:${this.ONU}\r\n`
          if (this.onuMode === 'bridge') {
            this.configOutput += '  service internet gemport 1 vlan 10\r\n'
          }
          if (this.onuMode === 'route') {
            this.configOutput += '  service internet type internet iphost 1 gemport 1 vlan 10\r\n'
          }
          if (this.voip1Enable || this.voip2Enable || this.externalATAEnable) {
            if (!this.voip1Enable && !this.voip2Enable) {
              this.configOutput += '  service voip gemport 2 vlan 20\r\n'
            } else {
              this.configOutput += '  service voip type voip iphost 2 gemport 2 vlan 20\r\n'
              this.configOutput += '  voip protocol sip\r\n'
            }
          }
          if (this.onuMode === 'route') {
            this.configOutput += `  pppoe 1 nat enable user ${this.username}${this.domain} password ${this.internetPassword}\r\n`
          }
          if (this.voip1Enable || this.voip2Enable) {
            this.configOutput += '  ip-host 2 dhcp-enable true ping-response true traceroute-response true\r\n'
            this.configOutput += `  sip-agent 1 proxy-server ${this.sipDomain}.totbb.net primary-dns 0.0.0.0 tcpudp-port 5060 host 2\r\n`
            if (this.voip1Enable) {
              this.configOutput += `  sip-user pots_0/1 sip-agent 1 user-aor ${this.voip1Username} username ${this.voip1Username} password ${this.voip1Password}\r\n`
            }
            if (this.voip2Enable) {
              this.configOutput += `  sip-user pots_0/2 sip-agent 1 user-aor ${this.voip2Username} username ${this.voip2Username} password ${this.voip2Password}\r\n`
            }
          }
          if (this.onuMode === 'bridge') {
            this.configOutput += '  vlan port eth_0/1 mode tag vlan 10\r\n'
          }
          if ((this.onuEthernetPort > 1) && (this.externalATAEnable)) {
            this.configOutput += `  vlan port eth_0/${this.onuEthernetPort} mode tag vlan 20\r\n`
          }
          if (this.onuMode === 'bridge') {
            this.configOutput += '  dhcp-ip ethuni eth_0/1 forbidden\r\n'
          }
          if ((this.onuEthernetPort > 1) && (this.externalATAEnable)) {
            this.configOutput += `  dhcp-ip ethuni eth_0/${this.onuEthernetPort} from-internet\r\n`
          }
          if ((this.onuMode === 'bridge' || this.onuMode === 'voipOnly') && (this.onuEthernetPort > 1)) {
            let startPort = 1
            let endPort = this.onuEthernetPort
            if (this.onuMode === 'bridge') {
              startPort = 2
            }
            if (this.externalATAEnable) {
              endPort = this.onuEthernetPort - 1
            }
            // eslint-disable-next-line max-len
            for (let ethernetPortIndex = startPort; ethernetPortIndex <= endPort; ethernetPortIndex += 1) {
              this.configOutput += `  interface eth eth_0/${ethernetPortIndex} state lock\r\n`
            }
          }

          this.configOutput += '!\r\n'

          this.showCommentConfig = false
          this.configCommentTitle = ''
          this.configCommentOutput = ''
        }
        if (this.oltVersion === 'C3XX V2') {
          this.configOutput = '!\r\n'
          this.configOutput += `interface gpon-olt_${this.Shelf}/${this.Slot}/${this.PON}\r\n`
          if (this.deleteONU) {
            this.configOutput += `  no onu ${this.ONU}\r\n`
          }
          this.configOutput += `  onu ${this.ONU} type ${this.onuType} sn ${this.onuSN}\r\n`
          this.configOutput += '!\r\n'
          this.configOutput += `interface gpon-onu_${this.Shelf}/${this.Slot}/${this.PON}:${this.ONU}\r\n`
          this.configOutput += '  name '
          if (this.username !== '') {
            this.configOutput += `${this.username}`
            if (this.voip1Username !== '') {
              this.configOutput += ', '
            }
          }
          if (this.voip1Username !== '') {
            this.configOutput += `${this.voip1Username}`
            if (this.voip2Username !== '') {
              this.configOutput += ', '
            }
          }
          if (this.voip2Username !== '') {
            this.configOutput += `${this.voip2Username}`
          }
          this.configOutput += ' |'
          if (this.description !== '') {
            this.configOutput += ` ${this.description}`
          }
          this.configOutput += '\r\n'
          this.configOutput += '  sn-bind disable\r\n'
          if ((this.onuMode === 'route') || (this.onuMode === 'bridge')) {
            this.configOutput += '  tcont 1 profile UNLIMITED_UP\r\n'
          }
          if (this.voip1Enable || this.voip2Enable || this.externalATAEnable) {
            this.configOutput += '  tcont 2 profile VOIP512K\r\n'
          }
          if ((this.onuMode === 'route') || (this.onuMode === 'bridge')) {
            this.configOutput += '  gemport 1 tcont 1\r\n'
            this.configOutput += '  gemport 1 traffic-limit downstream UNLIMITED_DOWN\r\n'
          }
          if (this.voip1Enable || this.voip2Enable || this.externalATAEnable) {
            this.configOutput += '  gemport 2 tcont 2\r\n'
            this.configOutput += '  gemport 2 traffic-limit downstream VOIP512K\r\n'
          }
          if ((this.onuMode === 'route') || (this.onuMode === 'bridge')) {
            this.configOutput += '  security max-mac-learn 2 vport 1\r\n'
          }
          if (this.voip1Enable || this.voip2Enable || this.externalATAEnable) {
            this.configOutput += '  security max-mac-learn 2 vport 2\r\n'
          }
          if ((this.onuMode === 'route') || (this.onuMode === 'bridge')) {
            this.configOutput += '  switchport mode hybrid vport 1\r\n'
          }
          if (this.voip1Enable || this.voip2Enable || this.externalATAEnable) {
            this.configOutput += '  switchport mode hybrid vport 2\r\n'
          }
          if ((this.onuMode === 'route') || (this.onuMode === 'bridge')) {
            this.configOutput += `  service-port 1 vport 1 user-vlan 10 vlan ${this.serviceVLAN}\r\n`
          }
          if (this.voip1Enable || this.voip2Enable || this.externalATAEnable) {
            this.configOutput += `  service-port 2 vport 2 user-vlan 20 vlan ${this.sipVLAN}\r\n`
          }
          this.configOutput += '!\r\n'
          this.configOutput += `pon-onu-mng gpon-onu_${this.Shelf}/${this.Slot}/${this.PON}:${this.ONU}\r\n`
          if (this.onuMode === 'bridge') {
            this.configOutput += '  service internet gemport 1 vlan 10\r\n'
          }
          if (this.onuMode === 'route') {
            this.configOutput += '  service internet gemport 1 iphost 1 vlan 10\r\n'
          }
          if (this.voip1Enable || this.voip2Enable || this.externalATAEnable) {
            if (!this.voip1Enable && !this.voip2Enable) {
              this.configOutput += '  service voip gemport 2 vlan 20\r\n'
            } else {
              this.configOutput += '  service voip gemport 2 iphost 2 vlan 20\r\n'
              this.configOutput += '  voip protocol sip\r\n'
              this.configOutput += '  voip-ip mode dhcp vlan-profile voip host 2\r\n'
            }
          }
          if (this.onuMode === 'route') {
            this.configOutput += `  wan-ip 1 mode pppoe username ${this.username}${this.domain} password ${this.internetPassword} vlan-profile internet host 1\r\n`
          }
          if (this.voip1Enable || this.voip2Enable) {
            if (this.voip1Enable) {
              this.configOutput += `  sip-service pots_0/1 profile ${this.sipDomain} userid ${this.voip1Username} username ${this.voip1Username} password ${this.voip1Password}\r\n`
            }
            if (this.voip2Enable) {
              this.configOutput += `  sip-service pots_0/2 profile ${this.sipDomain} userid ${this.voip2Username} username ${this.voip2Username} password ${this.voip2Password}\r\n`
            }
            this.configOutput += '  ip-host 2 dhcp-enable enable ping-response enable traceroute-response enable\r\n'
          }
          if (this.onuMode === 'bridge') {
            this.configOutput += '  vlan port eth_0/1 mode tag vlan 10\r\n'
          }
          if ((this.onuEthernetPort > 1) && (this.externalATAEnable)) {
            this.configOutput += `  vlan port eth_0/${this.onuEthernetPort} mode tag vlan 20\r\n`
          }
          if (this.onuMode === 'bridge') {
            this.configOutput += '  dhcp-ip ethuni eth_0/1 forbidden\r\n'
          }
          if ((this.onuEthernetPort > 1) && (this.externalATAEnable)) {
            this.configOutput += `  dhcp-ip ethuni eth_0/${this.onuEthernetPort} from-internet\r\n`
          }
          if ((this.onuMode === 'bridge' || this.onuMode === 'voipOnly') && (this.onuEthernetPort > 1)) {
            let startPort = 1
            let endPort = this.onuEthernetPort
            if (this.onuMode === 'bridge') {
              startPort = 2
            }
            if (this.externalATAEnable) {
              endPort = this.onuEthernetPort - 1
            }
            // eslint-disable-next-line max-len
            for (let ethernetPortIndex = startPort; ethernetPortIndex <= endPort; ethernetPortIndex += 1) {
              this.configOutput += `  interface eth eth_0/${ethernetPortIndex} state lock\r\n`
            }
          }
          this.configOutput += '!\r\n'
        }
        if (this.oltVersion === 'C6XX V1') {
          this.configOutput = '!\r\n'
          this.configOutput += `interface gpon_olt-${this.Shelf}/${this.Slot}/${this.PON}\r\n`
          if (this.deleteONU) {
            this.configOutput += `  no onu ${this.ONU}\r\n`
          }
          this.configOutput += `  onu ${this.ONU} type ${this.onuType} sn ${this.onuSN}\r\n`
          this.configOutput += '!\r\n'
          this.configOutput += `interface gpon_onu-${this.Shelf}/${this.Slot}/${this.PON}:${this.ONU}\r\n`
          this.configOutput += '  name '
          if (this.username !== '') {
            this.configOutput += `${this.username}`
            if (this.voip1Username !== '') {
              this.configOutput += ', '
            }
          }
          if (this.voip1Username !== '') {
            this.configOutput += `${this.voip1Username}`
            if (this.voip2Username !== '') {
              this.configOutput += ', '
            }
          }
          if (this.voip2Username !== '') {
            this.configOutput += `${this.voip2Username}`
          }
          this.configOutput += ' |'
          if (this.description !== '') {
            this.configOutput += ` ${this.description}`
          }
          this.configOutput += '\r\n'
          if ((this.onuMode === 'route') || (this.onuMode === 'bridge')) {
            this.configOutput += '  tcont 1 name internet profile UNLIMITED_UP\r\n'
          }
          if (this.voip1Enable || this.voip2Enable || this.externalATAEnable) {
            this.configOutput += '  tcont 2 name voip profile VOIP512K\r\n'
          }
          if ((this.onuMode === 'route') || (this.onuMode === 'bridge')) {
            this.configOutput += '  gemport 1 name internet tcont 1\r\n'
          }
          if (this.voip1Enable || this.voip2Enable || this.externalATAEnable) {
            this.configOutput += '  gemport 2 name voip tcont 2\r\n'
          }
          this.configOutput += '!\r\n'
          this.configOutput += `pon-onu-mng gpon_onu-${this.Shelf}/${this.Slot}/${this.PON}:${this.ONU}\r\n`
          if (this.onuMode === 'bridge' || this.onuMode === 'route') {
            this.configOutput += '  service internet gemport 1 vlan 10\r\n'
          }
          if (this.voip1Enable || this.voip2Enable || this.externalATAEnable) {
            if (!this.voip1Enable && !this.voip2Enable) {
              this.configOutput += '  service voip gemport 2 vlan 20\r\n'
            } else {
              this.configOutput += '  service voip gemport 2 vlan 20\r\n'
              this.configOutput += '  voip protocol sip\r\n'
              this.configOutput += '  voip-ip ipv4 mode dhcp vlan-profile voip host 2\r\n'
              this.configOutput += '  voip-ip ipv4 ping-response enable traceroute-response enable\r\n'
            }
          }
          if (this.onuMode === 'route') {
            this.configOutput += `  wan-ip ipv4 mode pppoe username ${this.username}${this.domain} password ${this.internetPassword} vlan-profile internet host 1\r\n`
          }
          if (this.voip1Enable || this.voip2Enable) {
            if (this.voip1Enable) {
              this.configOutput += `  sip-service pots_0/1 profile ${this.sipDomain} userid ${this.voip1Username} username ${this.voip1Username} password ${this.voip1Password}\r\n`
            }
            if (this.voip2Enable) {
              this.configOutput += `  sip-service pots_0/2 profile ${this.sipDomain} userid ${this.voip2Username} username ${this.voip2Username} password ${this.voip2Password}\r\n`
            }
          }
          if (this.onuMode === 'bridge') {
            this.configOutput += '  vlan port eth_0/1 mode tag vlan 10\r\n'
          }
          if ((this.onuEthernetPort > 1) && (this.externalATAEnable)) {
            this.configOutput += `  vlan port eth_0/${this.onuEthernetPort} mode tag vlan 20\r\n`
          }
          if (this.onuMode === 'bridge') {
            this.configOutput += '  dhcp-ip ethuni eth_0/1 forbidden\r\n'
          }
          if ((this.onuEthernetPort > 1) && (this.externalATAEnable)) {
            this.configOutput += `  dhcp-ip ethuni eth_0/${this.onuEthernetPort} from-internet\r\n`
          }
          if ((this.onuMode === 'bridge' || this.onuMode === 'voipOnly') && (this.onuEthernetPort > 1)) {
            let startPort = 1
            let endPort = this.onuEthernetPort
            if (this.onuMode === 'bridge') {
              startPort = 2
            }
            if (this.externalATAEnable) {
              endPort = this.onuEthernetPort - 1
            }
            // eslint-disable-next-line max-len
            for (let ethernetPortIndex = startPort; ethernetPortIndex <= endPort; ethernetPortIndex += 1) {
              this.configOutput += `  interface eth eth_0/${ethernetPortIndex} state lock\r\n`
            }
          }
          this.configOutput += '!\r\n'
          if ((this.onuMode === 'route') || (this.onuMode === 'bridge')) {
            this.configOutput += `interface vport-${this.Shelf}/${this.Slot}/${this.PON}.${this.ONU}:1\r\n`
            this.configOutput += `  service-port 1 user-vlan 10 vlan ${this.serviceVLAN}\r\n`
            this.configOutput += '  qos traffic-policy UNLIMITED_DOWN direction egress\r\n'
            this.configOutput += '  security max-mac-learn 2\r\n'
            this.configOutput += '!\r\n'
          }
          if (this.voip1Enable || this.voip2Enable || this.externalATAEnable) {
            this.configOutput += `interface vport-${this.Shelf}/${this.Slot}/${this.PON}.${this.ONU}:2\r\n`
            this.configOutput += `  service-port 2 user-vlan 20 vlan ${this.sipVLAN}\r\n`
            this.configOutput += '  qos traffic-policy VOIP512K direction egress\r\n'
            this.configOutput += '  security max-mac-learn 2\r\n'
            this.configOutput += '!\r\n'
          }
          this.configOutput += '!\r\n'
        }
        if (this.oltVersion === 'C3XX V2') {
          this.showCommentConfig = true
          this.configCommentTitle1 = 'สร้าง ONU Profile sipp13 sipp10 (กรณี OLT นั้น ยังไม่มี ONU Profile)'
          this.configCommentOutput1 = '!\r\n'
          this.configCommentOutput1 += 'gpon\r\n'
          this.configCommentOutput1 += '  onu profile sip sipp13 proxy sipp13.totbb.net\r\n'
          this.configCommentOutput1 += '  onu profile sip sipp13 registrar sipp13.totbb.net\r\n'
          this.configCommentOutput1 += '  onu profile sip sipp10 proxy sipp10.totbb.net\r\n'
          this.configCommentOutput1 += '  onu profile sip sipp10 registrar sipp10.totbb.net\r\n'
          this.configCommentOutput1 += '  onu profile vlan internet tag-mode tag cvlan 10\r\n'
          this.configCommentOutput1 += '  onu profile vlan voip tag-mode tag cvlan 20\r\n'
          this.configCommentOutput1 += '!\r\n'

          this.configCommentTitle2 = 'สร้าง ONU Profile sipp32 (กรณี OLT นั้น ยังไม่มี ONU Profile)'
          this.configCommentOutput2 = '!\r\n'
          this.configCommentOutput2 += 'gpon\r\n'
          this.configCommentOutput2 += '  onu profile sip sipp32 proxy sipp32.totbb.net\r\n'
          this.configCommentOutput2 += '  onu profile sip sipp32 registrar sipp32.totbb.net\r\n'
          this.configCommentOutput2 += '  onu profile vlan internet tag-mode tag cvlan 10\r\n'
          this.configCommentOutput2 += '  onu profile vlan voip tag-mode tag cvlan 20\r\n'
          this.configCommentOutput2 += '!\r\n'
        } else if (this.oltVersion === 'C6XX V1') {
          this.showCommentConfig = true
          this.configCommentTitle1 = 'สร้าง ONU Profile sipp13 sipp10 (กรณี OLT นั้น ยังไม่มี ONU Profile)'
          this.configCommentOutput1 = '!\r\n'
          this.configCommentOutput1 += 'gpon\r\n'
          this.configCommentOutput1 += '  onu profile sip sipp13 proxy sipp13.totbb.net registrar sipp13.totbb.net\r\n'
          this.configCommentOutput1 += '  onu profile sip sipp10 proxy sipp10.totbb.net registrar sipp10.totbb.net\r\n'
          this.configCommentOutput1 += '  onu profile vlan internet tag-mode tag cvlan 10\r\n'
          this.configCommentOutput1 += '  onu profile vlan voip tag-mode tag cvlan 20\r\n'
          this.configCommentOutput1 += '!\r\n'

          this.configCommentTitle2 = 'สร้าง ONU Profile sipp32 (กรณี OLT นั้น ยังไม่มี ONU Profile)'
          this.configCommentOutput2 = '!\r\n'
          this.configCommentOutput2 += 'gpon\r\n'
          this.configCommentOutput2 += '  onu profile sip sipp32 proxy sipp32.totbb.net registrar sipp10.totbb.net\r\n'
          this.configCommentOutput2 += '  onu profile vlan internet tag-mode tag cvlan 10\r\n'
          this.configCommentOutput2 += '  onu profile vlan voip tag-mode tag cvlan 20\r\n'
          this.configCommentOutput2 += '!\r\n'
        } else {
          this.showCommentConfig = false
          this.configCommentTitle1 = ''
          this.configCommentOutput1 = ''
          this.configCommentTitle2 = ''
          this.configCommentOutput2 = ''
        }
      }
    },
    onuTypeChanged() {
      this.voip1Enable = false
      this.voip1EnableCheckboxDisabled = false
      this.voip2Enable = false
      this.externalATAEnable = false
      this.domain = '@fttxhome'
      this.internetPassword = ''
      this.sipDomain = ''
      // this.sipVLAN = ''
      this.voip1Username = ''
      this.voip1Password = ''
      this.voip2Username = ''
      this.voip2Password = ''

      switch (this.onuType) {
        case 'ZTE-F601':
          this.onuModeItems = [
            { label: 'Bridge', value: 'bridge' },
          ]
          this.onuMode = 'bridge'
          this.onuEthernetPort = 1
          this.onuVoIPPort = 0
          break
        case 'ZTE-F612':
          this.onuModeItems = [
            { label: 'Bridge', value: 'bridge' },
            { label: 'Route', value: 'route' },
            { label: 'VoIP (No Internet)', value: 'voipOnly' },
          ]
          this.onuMode = 'bridge'
          this.onuEthernetPort = 2
          this.onuVoIPPort = 1
          break
        case 'ZTE-F620':
          this.onuModeItems = [
            { label: 'Bridge', value: 'bridge' },
            { label: 'Route', value: 'route' },
            { label: 'VoIP (No Internet)', value: 'voipOnly' },
          ]
          this.onuMode = 'bridge'
          this.onuEthernetPort = 4
          this.onuVoIPPort = 2
          break
        case 'ZTE-F600W':
          this.onuModeItems = [
            { label: 'Bridge', value: 'bridge' },
            { label: 'Route', value: 'route' },
          ]
          this.onuMode = 'route'
          this.onuEthernetPort = 4
          this.onuVoIPPort = 0
          break
        case 'ZTE-F660':
          this.onuModeItems = [
            { label: 'Bridge', value: 'bridge' },
            { label: 'Route', value: 'route' },
            { label: 'VoIP (No Internet)', value: 'voipOnly' },
          ]
          this.onuMode = 'route'
          this.onuEthernetPort = 4
          this.onuVoIPPort = 2
          break
        case 'ZTE-F6600':
          this.onuModeItems = [
            { label: 'Bridge', value: 'bridge' },
            { label: 'Route', value: 'route' },
            { label: 'VoIP (No Internet)', value: 'voipOnly' },
          ]
          this.onuMode = 'route'
          this.onuEthernetPort = 4
          this.onuVoIPPort = 2
          break
        case 'ZTE-F670':
          this.onuModeItems = [
            { label: 'Bridge', value: 'bridge' },
            { label: 'Route', value: 'route' },
            { label: 'VoIP (No Internet)', value: 'voipOnly' },
          ]
          this.onuMode = 'route'
          this.onuEthernetPort = 4
          this.onuVoIPPort = 2
          break
        case 'ZTE-F670L':
          this.onuModeItems = [
            { label: 'Bridge', value: 'bridge' },
            { label: 'Route', value: 'route' },
            { label: 'VoIP (No Internet)', value: 'voipOnly' },
          ]
          this.onuMode = 'route'
          this.onuEthernetPort = 4
          this.onuVoIPPort = 2
          break
        case 'ZTE-F680':
          this.onuModeItems = [
            { label: 'Bridge', value: 'bridge' },
            { label: 'Route', value: 'route' },
            { label: 'VoIP (No Internet)', value: 'voipOnly' },
          ]
          this.onuMode = 'route'
          this.onuEthernetPort = 4
          this.onuVoIPPort = 2
          break
        case 'ZTE-F6201B':
          this.onuModeItems = [
            { label: 'Bridge', value: 'bridge' },
            { label: 'Route', value: 'route' },
            { label: 'VoIP (No Internet)', value: 'voipOnly' },
          ]
          this.onuMode = 'route'
          this.onuEthernetPort = 4
          this.onuVoIPPort = 2
          break
        case 'ZTE-F220':
          this.onuModeItems = [
            { label: 'Bridge', value: 'bridge' },
            { label: 'Route', value: 'route' },
            { label: 'VoIP (No Internet)', value: 'voipOnly' },
          ]
          this.onuMode = 'route'
          this.onuEthernetPort = 4
          this.onuVoIPPort = 2
          break
        case 'ZTE-F260A':
          this.onuModeItems = [
            { label: 'Bridge', value: 'bridge' },
            { label: 'Route', value: 'route' },
            { label: 'VoIP (No Internet)', value: 'voipOnly' },
          ]
          this.onuMode = 'route'
          this.onuEthernetPort = 4
          this.onuVoIPPort = 2
          break
        default:
          this.onuModeItems = ''
          this.onuMode = ''
          this.onuEthernetPort = 0
          this.onuVoIPPort = 0
      }
    },

    calServiceVlan() {
      if ((this.domain === '@fttxhome') && (this.slot !== '')) {
        this.serviceVLAN = this.Slot + 3000
        this.sipVLAN = this.Slot + 2000
      } else if ((this.domain === '@fttxbiz') && (this.slot !== '')) {
        this.serviceVLAN = 3031
        this.sipVLAN = this.Slot + 2000
      } else if ((this.domain === '@government') && (this.slot !== '')) {
        this.serviceVLAN = 3031
        this.sipVLAN = this.Slot + 2000
      } else if ((this.domain === '@obec-net') && (this.slot !== '')) {
        this.serviceVLAN = 3000
        this.sipVLAN = this.Slot + 2000
      }
    },

    onuModeChanged() {
      this.$refs.form.resetValidation()
      // this.$refs.form.reset();

      // this.onuType = ''
      // this.onuMode = ''
      // this.onuModeItems = ''
      // this.onuVoIPPort = 0
      // this.onuSN = ''
      this.voip1Enable = false
      this.voip1EnableCheckboxDisabled = false
      this.voip2Enable = false
      this.externalATAEnable = false
      this.username = ''
      this.domain = '@fttxhome'
      this.internetPassword = ''
      // this.serviceVLAN = ''
      this.sipDomain = ''
      // this.sipVLAN = ''
      this.voip1Username = ''
      this.voip1Password = ''
      this.voip2Username = ''
      this.voip2Password = ''
      // this.oltVersion = 1
      // this.Shelf = 1
      // this.Slot = ''
      // this.PON = ''
      // this.ONU = ''
      this.deleteONU = false
      this.configOutput = ''
      this.showCommentConfig = false
      this.configCommentTitle = ''
      this.configCommentOutput = ''
      if (this.onuMode === 'voipOnly') {
        this.voip1Enable = true
        this.voip1EnableCheckboxDisabled = true
      }
      this.calServiceVlan()
    },
    clearConfig() {
      this.$refs.form.resetValidation()
      // this.$refs.form.reset();

      this.onuType = ''
      this.onuMode = ''
      this.onuModeItems = ''
      this.onuVoIPPort = 0
      this.onuSN = ''
      this.voip1Enable = false
      this.voip1EnableCheckboxDisabled = false
      this.voip2Enable = false
      this.externalATAEnable = false
      this.username = ''
      this.domain = '@fttxhome'
      this.internetPassword = ''
      this.serviceVLAN = ''
      this.sipDomain = ''
      this.sipVLAN = ''
      this.voip1Username = ''
      this.voip1Password = ''
      this.voip2Username = ''
      this.voip2Password = ''
      this.description = ''
      this.oltVersion = 2
      this.Shelf = 1
      this.Slot = ''
      this.PON = ''
      this.ONU = ''
      this.deleteONU = false
      this.configOutput = ''
      this.showCommentConfig = false
      this.configCommentTitle = ''
      this.configCommentOutput = ''
    },
    voip1EnableChanged() {
      if (!this.voip1Enable) {
        this.voip1Username = ''
        this.voip1Password = ''
        if (!this.voip2Enable) {
          this.sipDomain = ''
          // this.sipVLAN = ''
        }
      }
    },
    voip2EnableChanged() {
      if (!this.voip2Enable) {
        this.voip2Username = ''
        this.voip2Password = ''
        if (!this.voip1Enable) {
          this.sipDomain = ''
          // this.sipVLAN = ''
        }
      }
    },
    externalATAEnableChanged() {
      // this.sipVLAN = ''
    },
    onCopy() {
      // alert('You just copied: ' + e.text)
    },
    onCopyError() {
      // alert('Failed to copy config')
    },
  },
}
</script>

<style lang="styl">
.custom-monospace-textarea {
    font-family:  monospace;
    /*line-height: 95%;*/
}
</style>
